import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const style = {
  backgroundColor: "white",
  cursor: "move",
};

export const Avvik_PL_Item = ({
  item,
  index,
  activePL,
  movePL,
  setActivePL,
  TimelineForYear,
  convertNumber,
  data,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "tr",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      movePL(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "tr",
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 1 : 1;
  drag(drop(ref));

  return (
    <React.Fragment key={index}>
      <tr
        style={{ ...style, opacity }}
        data-handler-id={handlerId}
        ref={ref}
        onClick={() => {
          setActivePL((prev) => {
            if (prev.includes(item.prosjektleder)) {
              return prev.filter((leader) => leader !== item.prosjektleder);
            } else {
              return [...prev, item.prosjektleder];
            }
          });
        }}
      >
        <th scope="row">{item.prosjektleder}</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
      </tr>
      {activePL.includes(item.prosjektleder) && (
        <>
          {item.data.map((prosj, index) => (
            <tr key={index + "prosj"}>
              <td>
                {prosj.prosjektnr} - {prosj.prosjektnavn}
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Tooltip on top"
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø1)}</span>
                  {item.data[index].ø1 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø2)}</span>
                  {item.data[index].ø2 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 1}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø3)}</span>
                  {item.data[index].ø3 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 2}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø4)}</span>
                  {item.data[index].ø4 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 3}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø5)}</span>
                  {item.data[index].ø5 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 4}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø6)}</span>
                  {item.data[index].ø6 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 5}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø7)}</span>
                  {item.data[index].ø7 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 6}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø8)}</span>
                  {item.data[index].ø8 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 7}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø9)}</span>
                  {item.data[index].ø9 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 8}
                    />
                  )}
                </div>
              </td>
              <td
                className="text-center"
                style={{ padding: "5px 0px 0px 0px" }}
              >
                <div className="d-flex flex-column">
                  <span>{convertNumber(item.data[index].ø10)}</span>
                  {item.data[index].ø10 && (
                    <TimelineForYear
                      prosjekt={prosj.prosjektnr}
                      year={data.year + 9}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </>
      )}
      <div className="mb-2" />
    </React.Fragment>
  );
};
