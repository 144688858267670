import React, { useState, useEffect } from "react";
import { Alert, Button, Spinner, Table, Breadcrumb } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ProjectHistory from "../../projects/history";

export default function ReportAdmin() {
  const params = useParams();
  const navigate = useNavigate();

  const [reportedProj, setReportedProj] = useState([]);
  const [reportInfo, setReportInfo] = useState([]);

  const [loaded, setLoaded] = useState(false);

  // Ledervurdering aktivert
  const [lederVurderingActive, setLederVurderingActive] = useState(false);
  const [lederVurderinger, setLederVurderinger] = useState([]);
  const [lederVurdering, setLederVurdering] = useState("");

  // Retur
  const [returAction, setReturAction] = useState(false);
  const [returComment, setReturComment] = useState(null);

  // Data som er fjernet
  const [showRemovedData, setShowRemovedData] = useState(false);

  useEffect(() => {
    getReportedProj();
    if (params.pnr) {
      getReportInfo();
    } else {
      setLoaded(true);
    }

    return () => {
      setReportInfo([]);
      setLederVurderinger([]);
    };
  }, [params.pnr]);

  const getReportedProj = async () => {
    try {
      const res = await fetch(
        `https://prosjekt.tkva.no/api/admin/reports.php?reportedProj&type_id=${params.type_id}`,
        { credentials: "include" }
      );
      const data = await res.json();
      if (data?.[0]?.pnr) {
        if (!params.pnr) {
          navigate("" + data[0].pnr, { replace: true });
        }
      }
      setReportedProj(data);
    } catch (err) {
      return;
    }
  };

  const getReportInfo = async () => {
    setLoaded(false);
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/reports.php?reportInfo=" +
          params.pnr,
        { credentials: "include" }
      );
      const data = await res.json();
      setReportInfo(data);
      getReportLeder();
      setLoaded(true);
    } catch (err) {
      setLoaded(true);
      return;
    }
  };

  const getReportLeder = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/reports.php?lederVurdering=" +
          params.pnr,
        { credentials: "include" }
      );
      const data = await res.json();
      setLederVurderinger(data);
    } catch (err) {
      return;
    }
  };

  const sendRetur = async () => {
    const postData = {
      action: "RETUR",
      pnr: params.pnr,
      returComment: returComment,
    };
    try {
      await fetch("https://prosjekt.tkva.no/api/admin/reports.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
      getReportInfo();
      setReturAction(false);
      setReturComment(null);
      navigate("/admin/project/reports/" + params.type_id, { replace: true });
    } catch (err) {
      alert("Feilmelding: Rapporten ble ikke sendt i retur");
    }
  };

  const godkjenn = async () => {
    const postData = {
      action: "GODKJENN",
      pnr: params.pnr,
      data: reportInfo,
    };
    try {
      await fetch("https://prosjekt.tkva.no/api/admin/reports.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });

      setLederVurderingActive(false);

      // Finne rad og navigere til nytt prosjekt
      const navigateToProject = (projectId) => {
        navigate(`/admin/project/reports/${params.type_id}/${projectId}`, {
          replace: true,
        });
      };

      const index = reportedProj.findIndex((p) => p.pnr === params.pnr);

      if (index > -1) {
        // Finn neste eller forrige prosjekt basert på index
        const nextProj =
          index === 0
            ? reportedProj[index + 1]?.pnr
            : reportedProj[index + 1]?.pnr
            ? reportedProj[index + 1]?.pnr
            : reportedProj[index - 1]?.pnr;

        // Hvis neste prosjekt finnes, naviger til det, ellers naviger til hovedsiden
        nextProj
          ? navigateToProject(nextProj)
          : navigate(`/admin/project/reports/${params.type_id}`, {
              replace: true,
            });
      } else {
        // Hvis prosjektet ikke finnes i listen, naviger til hovedsiden
        navigate(`/admin/project/reports/${params.type_id}`, { replace: true });
      }

      //getReportInfo();
      //getReportedProj();
    } catch (err) {
      alert("Feilmelding: Rapporten ble ikke godkjent");
    }
  };

  const addLedervurdering = async () => {
    const postData = {
      action: "LEDERVURDERING",
      pnr: params.pnr,
      year: reportInfo[0].year,
      version: reportInfo[0].version,
      data: lederVurdering,
    };
    try {
      await fetch("https://prosjekt.tkva.no/api/admin/reports.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });

      //setLederVurdering("");
      getReportLeder();
    } catch (err) {
      alert("Feilmelding: Ledervurderingen ble ikke lagt inn");
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to={"../admin/project/reports/" + params.type_id}>
            Rapportering
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-8">
          <h5>Rapportering</h5>
          {reportInfo.length > 0 ? (
            <>
              <div className="d-flex justify-content-between">
                <h4>Prosjektoversikt</h4>
                <ProjectHistory project={reportInfo[0].pnr}>
                  <Button size="sm" variant="outline-primary">
                    <span>
                      <i className="bi bi-info-circle" />
                    </span>
                  </Button>
                </ProjectHistory>
              </div>
              <div className="content">
                <div className="d-flex justify-content-around">
                  <div>
                    <h6 className="text-center">Prosjekt</h6>
                    <span className="text-center">
                      {reportInfo[0].pnr} - {reportInfo[0].pname}
                    </span>
                  </div>
                  <div>
                    <h6 className="text-center">Hovedprosjekt</h6>
                    <span className="text-center">{reportInfo[0].parent}</span>
                  </div>
                  <div>
                    <h6 className="text-center">Enhet</h6>
                    <span className="text-center">{reportInfo[0].enhet}</span>
                  </div>
                </div>
              </div>
              <hr />
              <h5>Økonomiplan</h5>
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>Konto</th>
                      <th>Tjeneste</th>
                      <th>Tema</th>
                      {Array.from({ length: 10 }, (_, index) => {
                        return (
                          <th key={index} style={{ textAlign: "center" }}>
                            {parseInt(reportInfo[0].year) + index}
                          </th>
                        );
                      })}
                      <th>Notat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportInfo.map((report, rIndex) => {
                      return (
                        <tr key={rIndex}>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    konto: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.konto}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.konto}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    tjeneste: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.tjeneste}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.tjeneste}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    tema: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.tema}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.optema}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e1: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e1}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op1}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e2: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e2}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op2}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e3: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e3}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op3}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e4: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e4}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op4}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e5: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e5}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op5}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e6: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e6}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op6}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e7: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e7}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op7}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e8: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e8}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op8}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e9: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e9}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op9}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    e10: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.e10}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.op10}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                border: 0,
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                setReportInfo((prev) => {
                                  const newInfo = [...prev];
                                  newInfo[rIndex] = {
                                    ...newInfo[rIndex],
                                    lineComment: e.target.value,
                                  };
                                  return newInfo;
                                });
                              }}
                              defaultValue={report.lineComment}
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              style={{
                                width: "100%",
                                border: 0,
                                backgroundColor: "grey",
                                color: "white",
                                textAlign: "center",
                              }}
                              defaultValue={report.opLineComment}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex flex-row-reverse">
                <div>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => {
                      setReportInfo((prev) => {
                        if (!prev || prev.length <= 1) {
                          alert("Kan ikke slette siste linje");
                          return prev;
                        }
                        const upd = prev.slice(0, -1);
                        return upd;
                      });
                    }}
                  >
                    Fjern siste linje
                  </Button>
                </div>
              </div>
              {reportInfo?.[0]?.removedData?.length > 0 && (
                <>
                  <div className="mt-1" />
                  <Alert variant="warning">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        Det er linjer som er fjernet i denne rapporteringen
                      </span>
                      <Button
                        size="sm"
                        onClick={() => setShowRemovedData((prev) => !prev)}
                      >
                        {showRemovedData ? "Skjul data" : "Vis data"}
                      </Button>
                    </div>
                  </Alert>
                  {showRemovedData && (
                    <div className="table-responsive">
                      <Table variant="warning">
                        <thead>
                          <tr>
                            <th>Konto</th>
                            <th>Tjeneste</th>
                            <th>Tema</th>
                            {Array.from({ length: 10 }, (_, index) => {
                              return (
                                <th key={index} style={{ textAlign: "center" }}>
                                  {parseInt(
                                    reportInfo?.[0]?.removedData?.[0].year
                                  ) + index}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {reportInfo?.[0]?.removedData?.map(
                            (report, rIndex) => {
                              return (
                                <tr key={rIndex}>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.konto}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.tjeneste}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.tema}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e1}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e2}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e3}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e4}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e5}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e6}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e7}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e8}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e9}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      defaultValue={report.e10}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </>
              )}
              <hr />
              {reportInfo[0]?.phases.length > 0 && (
                <>
                  <h5>Faseinndeling</h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Skisse/forprosjekt</th>
                        <th>Detaljprosjektering</th>
                        <th>Utførelse</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Start</td>
                        <td>
                          <input
                            type="date"
                            defaultValue={
                              reportInfo[0]?.phases?.find(
                                (p) => p.phase === "skisse/forprosjekt"
                              )?.start || ""
                            }
                            onChange={(e) => {
                              const d = e.target.value; // Dato
                              const existingPhaseIndex =
                                reportInfo[0]?.phases.findIndex(
                                  (p) => p.phase === "skisse/forprosjekt"
                                );
                              if (existingPhaseIndex !== -1) {
                                // Oppdater sluttdato for fasen
                                const updatedPhases = [...reportInfo];
                                updatedPhases[0].phases[existingPhaseIndex] = {
                                  ...updatedPhases[0].phases[
                                    existingPhaseIndex
                                  ],
                                  start: d,
                                };
                                setReportInfo(updatedPhases);
                              } else {
                                // Legg til fase hvis utførelse ikke finnes
                                const newPhase = {
                                  phase: "skisse/forprosjekt",
                                  start: d,
                                  slutt: d,
                                };

                                // Denne må sjekkes ytterligere
                                const updatedReportInfo = [...reportInfo];
                                updatedReportInfo[0] = {
                                  ...updatedReportInfo[0],
                                  phases: [
                                    ...updatedReportInfo[0].phases,
                                    newPhase,
                                  ],
                                };

                                setReportInfo(updatedReportInfo);
                              }
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            defaultValue={
                              reportInfo[0]?.phases?.find(
                                (p) => p.phase === "detaljprosjektering"
                              )?.start || ""
                            }
                            onChange={(e) => {
                              const d = e.target.value; // Dato
                              const existingPhaseIndex =
                                reportInfo[0]?.phases.findIndex(
                                  (p) => p.phase === "detaljprosjektering"
                                );
                              if (existingPhaseIndex !== -1) {
                                // Oppdater sluttdato for fasen
                                const updatedPhases = [...reportInfo];
                                updatedPhases[0].phases[existingPhaseIndex] = {
                                  ...updatedPhases[0].phases[
                                    existingPhaseIndex
                                  ],
                                  start: d,
                                };
                                setReportInfo(updatedPhases);
                              } else {
                                // Legg til fase hvis utførelse ikke finnes
                                const newPhase = {
                                  phase: "detaljprosjektering",
                                  start: d,
                                  slutt: d,
                                };

                                // Denne må sjekkes ytterligere
                                const updatedReportInfo = [...reportInfo];
                                updatedReportInfo[0] = {
                                  ...updatedReportInfo[0],
                                  phases: [
                                    ...updatedReportInfo[0].phases,
                                    newPhase,
                                  ],
                                };

                                setReportInfo(updatedReportInfo);
                              }
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            defaultValue={
                              reportInfo[0]?.phases?.find(
                                (p) => p.phase === "utførelse"
                              )?.start || ""
                            }
                            onChange={(e) => {
                              const d = e.target.value; // Dato
                              const existingPhaseIndex =
                                reportInfo[0]?.phases.findIndex(
                                  (p) => p.phase === "utførelse"
                                );
                              if (existingPhaseIndex !== -1) {
                                // Oppdater sluttdato for fasen
                                const updatedPhases = [...reportInfo];
                                updatedPhases[0].phases[existingPhaseIndex] = {
                                  ...updatedPhases[0].phases[
                                    existingPhaseIndex
                                  ],
                                  start: d,
                                };
                                setReportInfo(updatedPhases);
                              } else {
                                // Legg til fase hvis utførelse ikke finnes
                                const newPhase = {
                                  phase: "utførelse",
                                  start: d,
                                  slutt: d,
                                };

                                // Denne må sjekkes ytterligere
                                const updatedReportInfo = [...reportInfo];
                                updatedReportInfo[0] = {
                                  ...updatedReportInfo[0],
                                  phases: [
                                    ...updatedReportInfo[0].phases,
                                    newPhase,
                                  ],
                                };

                                setReportInfo(updatedReportInfo);
                              }
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Slutt</td>
                        <td>
                          <input
                            type="date"
                            defaultValue={
                              reportInfo[0]?.phases?.find(
                                (p) => p.phase === "skisse/forprosjekt"
                              )?.slutt || ""
                            }
                            onChange={(e) => {
                              const d = e.target.value; // Dato
                              const existingPhaseIndex =
                                reportInfo[0]?.phases.findIndex(
                                  (p) => p.phase === "skisse/forprosjekt"
                                );
                              if (existingPhaseIndex !== -1) {
                                // Oppdater sluttdato for fasen
                                const updatedPhases = [...reportInfo];
                                updatedPhases[0].phases[existingPhaseIndex] = {
                                  ...updatedPhases[0].phases[
                                    existingPhaseIndex
                                  ],
                                  slutt: d,
                                };
                                setReportInfo(updatedPhases);
                              } else {
                                // Legg til fase hvis utførelse ikke finnes
                                const newPhase = {
                                  phase: "skisse/forprosjekt",
                                  start: d,
                                  slutt: d,
                                };

                                // Denne må sjekkes ytterligere
                                const updatedReportInfo = [...reportInfo];
                                updatedReportInfo[0] = {
                                  ...updatedReportInfo[0],
                                  phases: [
                                    ...updatedReportInfo[0].phases,
                                    newPhase,
                                  ],
                                };

                                setReportInfo(updatedReportInfo);
                              }
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            defaultValue={
                              reportInfo[0]?.phases?.find(
                                (p) => p.phase === "detaljprosjektering"
                              )?.slutt || ""
                            }
                            onChange={(e) => {
                              const d = e.target.value; // Dato
                              const existingPhaseIndex =
                                reportInfo[0]?.phases.findIndex(
                                  (p) => p.phase === "detaljprosjektering"
                                );
                              if (existingPhaseIndex !== -1) {
                                // Oppdater sluttdato for fasen
                                const updatedPhases = [...reportInfo];
                                updatedPhases[0].phases[existingPhaseIndex] = {
                                  ...updatedPhases[0].phases[
                                    existingPhaseIndex
                                  ],
                                  slutt: d,
                                };
                                setReportInfo(updatedPhases);
                              } else {
                                // Legg til fase hvis utførelse ikke finnes
                                const newPhase = {
                                  phase: "detaljprosjektering",
                                  start: d,
                                  slutt: d,
                                };

                                // Denne må sjekkes ytterligere
                                const updatedReportInfo = [...reportInfo];
                                updatedReportInfo[0] = {
                                  ...updatedReportInfo[0],
                                  phases: [
                                    ...updatedReportInfo[0].phases,
                                    newPhase,
                                  ],
                                };

                                setReportInfo(updatedReportInfo);
                              }
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            defaultValue={
                              reportInfo[0]?.phases?.find(
                                (p) => p.phase === "utførelse"
                              )?.slutt || ""
                            }
                            onChange={(e) => {
                              const d = e.target.value; // Dato
                              const existingPhaseIndex =
                                reportInfo[0]?.phases.findIndex(
                                  (p) => p.phase === "utførelse"
                                );
                              if (existingPhaseIndex !== -1) {
                                // Oppdater sluttdato for fasen
                                const updatedPhases = [...reportInfo];
                                updatedPhases[0].phases[existingPhaseIndex] = {
                                  ...updatedPhases[0].phases[
                                    existingPhaseIndex
                                  ],
                                  slutt: d,
                                };
                                setReportInfo(updatedPhases);
                              } else {
                                // Legg til fase hvis utførelse ikke finnes
                                const newPhase = {
                                  phase: "utførelse",
                                  start: d,
                                  slutt: d,
                                };

                                // Denne må sjekkes ytterligere
                                const updatedReportInfo = [...reportInfo];
                                updatedReportInfo[0] = {
                                  ...updatedReportInfo[0],
                                  phases: [
                                    ...updatedReportInfo[0].phases,
                                    newPhase,
                                  ],
                                };

                                setReportInfo(updatedReportInfo);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                </>
              )}
              {!returAction ? (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <h5>
                      {lederVurderingActive ? "Ledervurdering" : "Kommentar"}
                    </h5>
                    {lederVurderingActive ? (
                      <Button
                        size="sm"
                        onClick={() => setLederVurderingActive(false)}
                      >
                        kommentar
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        onClick={() => setLederVurderingActive(true)}
                      >
                        ledervurdering
                      </Button>
                    )}
                  </div>
                  {!lederVurderingActive ? (
                    <textarea
                      style={{ width: "100%", border: 0, height: 180 }}
                      defaultValue={reportInfo[0].comment}
                      onChange={(e) => {
                        setReportInfo((prev) => {
                          const newInfo = [...prev];
                          newInfo[0] = {
                            ...newInfo[0],
                            comment: e.target.value,
                          };
                          return newInfo;
                        });
                      }}
                    />
                  ) : (
                    <div>
                      <div className="col">
                        <div style={{ maxHeight: "25vh", overflowY: "auto" }}>
                          {lederVurderinger?.map((l, pIndex) => {
                            return (
                              <div
                                key={pIndex}
                                style={{
                                  backgroundColor: "white",
                                  borderRadius: 5,
                                  padding: 5,
                                  marginBottom: 5,
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <div>{l.comment}</div>
                                  <div style={{ marginLeft: 15 }}>
                                    <small style={{ textDecoration: "none" }}>
                                      Rapportert av {l.user} <br /> {l.created}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div>
                          <hr />
                          <textarea
                            placeholder="Ny ledervurderinger..."
                            style={{ width: "100%", minHeight: "120px" }}
                            onChange={(e) => setLederVurdering(e.target.value)}
                            defaultValue={lederVurdering}
                          />
                          <div className="d-flex flex-row-reverse">
                            <Button
                              size="sm"
                              onClick={() => addLedervurdering()}
                            >
                              Legg til ledervurdering
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="d-flex justify-content-end">
                    <div>
                      <Button
                        size="sm"
                        variant="warning"
                        onClick={() => setReturAction(true)}
                      >
                        <i className="bi bi-arrow-return-left" /> Send rapport i
                        retur
                      </Button>
                      <Button
                        size="sm"
                        variant="success"
                        style={{
                          marginLeft: 15,
                        }}
                        onClick={() => godkjenn()}
                      >
                        <i className="bi bi-save" /> Godkjenn rapport
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-warning p-2">
                    <h5>Retur med kommentar</h5>
                    <textarea
                      style={{ width: "100%", border: 0, height: 180 }}
                      onChange={(e) => {
                        setReturComment(e.target.value);
                      }}
                    />
                    <hr />
                    <div className="d-flex justify-content-end">
                      <div>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          onClick={() => sendRetur()}
                        >
                          Send rapport i retur
                        </Button>
                        <Button
                          size="sm"
                          variant="success"
                          style={{ marginLeft: 15 }}
                          onClick={() => setReturAction(false)}
                        >
                          Ikke returner
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : !loaded ? (
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <Spinner />
              </div>
            </div>
          ) : (
            <Alert variant="warning">Velg prosjekt</Alert>
          )}
        </div>

        <div className="col-4">
          <>
            <h5>Rapporterte prosjekt</h5>
            <div className="col">
              {reportedProj.map((proj, pIndex) => {
                return (
                  <div
                    key={pIndex}
                    className={params.pnr === proj.pnr && "bg-info bg-gradient"}
                    style={{
                      backgroundColor: "white",
                      borderRadius: 5,
                      padding: 5,
                      marginBottom: 5,
                    }}
                  >
                    <Link
                      to={
                        "../admin/project/reports/" +
                        params.type_id +
                        "/" +
                        proj.pnr
                      }
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <div className="d-flex flex-column">
                        <div>
                          {proj.pnr} - {proj.pname}
                        </div>
                        <div style={{ marginLeft: 15 }}>
                          <small style={{ textDecoration: "none" }}>
                            Rapportert {proj.updated} av {proj.user}
                          </small>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </div>
    </>
  );
}
