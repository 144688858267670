import React, { useState, useEffect } from "react";
import { Alert, Button, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ProjectHistory from "../projects/history";
import { useAuth } from "../../hooks/auth";

export default function Overview() {
  const { theme } = useAuth();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState(false);

  const [projects, setProjects] = useState([]);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    getProjects().then(() => {
      setLoaded(true);
    });
    getInfo();

    return () => {
      setLoaded(false);
    };
  }, []);

  const getProjects = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/overview.php", {
        credentials: "include",
      });
      const data = await res.json();
      if (data.proj.length < 1) {
        navigate("projects", { replace: true });
      }
      setProjects(data);
    } catch (err) {
      navigate("projects", { replace: true });
      setError(err);
    }
  };
  const getInfo = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/info.php", {
        credentials: "include",
      });
      const data = await res.json();
      setInfo(data);
    } catch (err) {
      return;
    }
  };

  return (
    <>
      {loaded ? (
        error ? (
          <Alert variant="danger">
            <b>Noe gikk galt.</b> Prøv igjen senere
          </Alert>
        ) : (
          <>
            <div id="overview" className="d-flex flex-wrap">
              <div className="mx-2 flex-grow-1 box-1">
                <div
                  className="card h-100"
                  style={{
                    backgroundColor: "transparent",
                    border: 0,
                    borderRadius: 0,
                  }}
                >
                  <Table borderless hover className="sticky-thc">
                    <thead>
                      <tr
                        style={{
                          height: 50,
                        }}
                      >
                        <th
                          style={{
                            width: "50%",
                            color: "#344050",
                            fontWeight: 500,
                            fontSize: 20,
                          }}
                          className="bg-light"
                        >
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/7604/7604036.png"
                            height={35}
                          />{" "}
                          &nbsp; Mine prosjekter
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            width: "25%",
                            color: "#344050",
                            fontWeight: 500,
                            fontSize: 20,
                          }}
                          className="bg-light"
                        >
                          Status
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            color: "#344050",
                            fontWeight: 500,
                            fontSize: 20,
                          }}
                          className="bg-light"
                        >
                          Handling
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!projects?.proj && (
                        <tr>
                          <td colSpan={3} className="text-center">
                            <Spinner />
                          </td>
                        </tr>
                      )}
                      {projects?.proj?.map((p, pIndex) => {
                        return (
                          <tr
                            key={pIndex}
                            style={{
                              fontSize: 16,
                              borderBottom: "0.5px #000 dotted",
                            }}
                          >
                            <td
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span>{p.pnr}</span>
                                <span>{p.pname}</span>
                              </div>
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              {parseInt(p.reportOpen) ? (
                                <>
                                  {parseInt(p.reportAccepted) ? (
                                    <>
                                      {parseInt(p.reportChanges) > 1 ? (
                                        <span className="text-success ">
                                          <i className="bi bi-check-circle" />{" "}
                                          Godkjent med endring
                                        </span>
                                      ) : (
                                        <Button
                                          variant="success-outline"
                                          className="d-flex flex-row flex-nowrap align-items-center p-0"
                                          style={{ cursor: "default" }}
                                        >
                                          <i className="bi bi-check-circle" />
                                          <span className="px-2 text-nowrap">
                                            Godkjent
                                          </span>
                                        </Button>
                                      )}
                                    </>
                                  ) : parseInt(p.reportSent) ? (
                                    <span className="text-success ">
                                      <i className="bi bi-cloud-download-fill" />{" "}
                                      Rapportert
                                    </span>
                                  ) : parseInt(p.reportSaved) ? (
                                    <span style={{ color: "#B87333" }}>
                                      <i className="bi bi-save" /> Kladdet
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      <i className="bi bi-x-circle-fill" /> Ikke
                                      rapportert
                                    </span>
                                  )}
                                </>
                              ) : (
                                <div
                                  style={{ cursor: "default" }}
                                  className="d-flex flex-row align-items-center"
                                >
                                  <span>Ikke åpen</span>
                                </div>
                              )}
                            </td>
                            <td style={{ width: "20%" }}>
                              {parseInt(p.reportOpen) ? (
                                <Link
                                  to={"reports/" + p.pnr}
                                  className="text-decoration-none"
                                  style={{ float: "right" }}
                                >
                                  {parseInt(p.reportSent) ? (
                                    <Button variant="success">
                                      Les rapport{" "}
                                      <i className="bi bi-arrow-bar-right" />
                                    </Button>
                                  ) : (
                                    <Button variant="success">
                                      Rapporter{" "}
                                      <i className="bi bi-arrow-bar-right" />
                                    </Button>
                                  )}
                                </Link>
                              ) : (
                                <ProjectHistory project={p.pnr}>
                                  <Button
                                    variant={
                                      theme === "dark" ? "dark" : "primary"
                                    }
                                    className="d-flex flex-row flex-nowrap align-items-center"
                                    style={{ float: "right" }}
                                  >
                                    <span className="px-2 text-nowrap text-light">
                                      Se prosjekt
                                    </span>
                                    <i className="bi bi-arrow-bar-right" />
                                  </Button>
                                </ProjectHistory>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="mx-2 flex-grow-1 box-2">
                <div
                  className="card mb-2"
                  style={{ borderRadius: 0, border: 0 }}
                >
                  <div
                    className="card-header d-flex flex-between-center py-2"
                    style={{
                      border: 0,
                      color: "white",
                      borderRadius: 0,
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/1786/1786640.png"
                      height={35}
                    />{" "}
                    &nbsp;
                    <h5
                      className="mb-0 pt-2"
                      style={{
                        color: "#000 !important",
                      }}
                    >
                      Rapportering
                    </h5>
                  </div>
                  <div
                    className="card-body pb-0 text-dark"
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {!projects?.reports && (
                      <div className="d-flex align-items-center justify-content-center mb-4">
                        <Spinner />
                      </div>
                    )}
                    {projects.reports?.map((r, rIndex) => {
                      return (
                        <React.Fragment key={rIndex}>
                          <div className="d-flex mb-3 align-items-center">
                            <div style={{ width: 45 }}>
                              <img
                                className="border h-100 w-100 object-fit-cover rounded-2"
                                src={r.typeImg}
                                alt=""
                              />
                            </div>
                            <div className="ms-3 flex-shrink-1 flex-grow-1">
                              <h6 className="mb-1">{r.typeName}</h6>
                              <div className="fs-10">
                                <span className="fw-medium text-600 ms-2">
                                  {parseInt(r.reportOpen) ? (
                                    <>
                                      <span>Rapporteringsfrist: </span>{" "}
                                      {r.endDate}
                                    </>
                                  ) : (
                                    "Stengt for rapportering"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr className="text-200" />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>

                <div
                  className="card mb-2"
                  style={{ borderRadius: 0, border: 0 }}
                >
                  <div
                    className="card-header d-flex flex-between-center py-2"
                    style={{
                      border: 0,
                      color: "white",
                      borderRadius: 0,
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/7248/7248985.png"
                      height={35}
                    />{" "}
                    &nbsp;
                    <h5
                      className="mb-0 pt-2"
                      style={{
                        color: "#000 !important",
                      }}
                    >
                      Informasjon
                    </h5>
                  </div>
                  <div
                    className="card-body pb-0 text-dark"
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {info.length <= 0 && (
                      <div className="d-flex align-items-center justify-content-center mb-4">
                        <Spinner />
                      </div>
                    )}
                    <div
                      style={{ maxHeight: "600px" }}
                      className="overflow-auto"
                    >
                      {info?.map((i, iIndex) => {
                        return (
                          <React.Fragment key={iIndex}>
                            <Alert variant={i.variant}>
                              <h5 className="alert-heading">{i.heading}</h5>
                              <p>{i.summary}</p>
                              <hr />
                              <p className="mb-0">{i.created}</p>
                            </Alert>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
