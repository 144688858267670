import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";

const UserContentContext = createContext();

export const ContentProvider = ({ children }) => {
  // Projects
  const [loaded, setLoaded] = useState(false); // False
  const [error, setError] = useState(false); // False
  const [projects, setProjects] = useState([]); // Hoveddata av prosjekter vi sender ut
  const [projectsData, setProjectsData] = useState([]); // Brukes som dummy for søkefunksjonen
  const [projectsDate, setProjectsDate] = useState(""); // Dato for prosjekt
  const [activeSub, setActiveSub] = useState([]); // Underprosjekt av enhet/type
  const [activeSubSub, setActiveSubSub] = useState([]); // Underprosjekt av prosjekt
  const [projectsTableScrollTop, setProjectsTableScrollTop] = useState(0);
  const [pinList, setPinList] = useState([]);

  // Brutto-nettometoden
  const [isBrutto, setIsBrutto] = useState(true);

  // Filter
  const mainProjects = useMemo(() => {
    let types = [];
    projectsData.map((p) => {
      types.push(p.name);
    });
    return types;
  }, [projectsData]);
  const PLinProjects = useMemo(() => {
    // Hente ut alle PL
    let PLs = new Set();

    // Iterere gjennom hovedprosjektene
    projects.forEach((type) => {
      // PL på hovedprosjekter
      type.subData?.forEach((subData) => {
        if (subData.pl) {
          PLs.add(subData.pl);
        }
        // PL på underprosjekter
        subData.subData?.forEach((subsubData) => {
          if (subsubData.pl) {
            PLs.add(subsubData.pl);
          }
        });
      });
    });

    // Konvertere Set til en array hvis nødvendig
    let uniquePLs = Array.from(PLs);

    return uniquePLs;
  }, [projects]);

  const [mainProjFilter, setMainProjFilter] = useState([]);
  const [plProjFilter, setPlProjFilter] = useState([]);

  // Location
  const location = useLocation();
  const locationRef = useRef(location.pathname);

  // Search Project
  const [keyword, setKeyword] = useState("");
  const [oldActiveSub, setOldActiveSub] = useState([]);
  const [oldActiveSubSub, setOldActiveSubSub] = useState([]);

  const getProjectData = async (brutto = true) => {
    try {
      if (brutto) {
        const res = await fetch("https://prosjekt.tkva.no/api/projects.php", {
          credentials: "include",
        });
        const data = await res.json();
        setProjectsData(data);
      } else {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/projects.php?netto",
          {
            credentials: "include",
          }
        );
        const data = await res.json();
        setProjectsData(data);
      }
      /*
      setProjectsData(data);
      setProjects(keyword.length > 1 ? searchProject(data) : data);
      */
      //setProjects(data);
      setProjectsDate(new Date().toLocaleTimeString());
      setLoaded(true);
      setError(false);
    } catch (err) {
      setLoaded(true);
      setError(err.message);
    }
  };

  const searchProject = (data) => {
    if (keyword.length >= 2) {
      const filteredProjects = data
        .map((project) => {
          const lowercasedKeyword = keyword.toLowerCase();

          // Sjekk både prosjektet og dets overordnede nivå
          const projectMatch =
            project?.pnr?.toLowerCase().includes(lowercasedKeyword) ||
            project?.pname?.toLowerCase().includes(lowercasedKeyword) ||
            project?.name?.toLowerCase().includes(lowercasedKeyword); // Sjekk navnet på det øverste nivået

          // Filtrering av subData
          const filteredSubData = project.subData
            .map((sub) => {
              const subMatch =
                (sub?.pnr || "").toLowerCase().includes(lowercasedKeyword) ||
                (sub?.pname || "").toLowerCase().includes(lowercasedKeyword);

              // Filtrering av subData.subData
              const filteredSubDataInSub = sub.subData.filter((subDataItem) => {
                return (
                  (subDataItem?.pnr || "")
                    .toLowerCase()
                    .includes(lowercasedKeyword) ||
                  (subDataItem?.pname || "")
                    .toLowerCase()
                    .includes(lowercasedKeyword)
                );
              });

              // Hvis det er en match i sub eller i subData.subData, returner sub med filtrerte subData
              if (subMatch || filteredSubDataInSub.length > 0) {
                return {
                  ...sub,
                  subData: filteredSubDataInSub, // Inkluder filtrerte subData
                };
              }

              return null; // Hvis ingen match, return null
            })
            .filter((sub) => sub !== null); // Filtrer ut nuller

          // Returner prosjektet hvis det er en match i prosjektet eller i noen av subData
          if (projectMatch || filteredSubData.length > 0) {
            return {
              ...project,
              subData: filteredSubData, // Inkluder filtrerte subData
              parent: project.parent,
            };
          }

          return null; // Hvis ingen match, return null
        })
        .filter((project) => project !== null); // Filtrer ut nuller

      // Legg til activeSub
      const newActiveSub = filteredProjects.map((mainData) => mainData.name);
      const newActiveSubSub = filteredProjects.flatMap(
        (mainData) => mainData.subData?.map((subData) => subData.pnr) || []
      );

      // Oppdaterer state én gang for hver
      setActiveSub(newActiveSub);
      setActiveSubSub(newActiveSubSub);

      // Hvis filteredProjects er tom
      if (!filteredProjects || filteredProjects.length === 0) {
        filteredProjects.push({
          name: "Ingen treff",
          img: "https://cdn-icons-png.flaticon.com/128/12399/12399008.png",
          version: 404,
          year: projectsData[0].year,
        });
      }

      return filteredProjects;
    } else {
      setActiveSub(oldActiveSub);
      setActiveSubSub(oldActiveSubSub);
      return data;
    }
  };

  // Set Data first time
  useEffect(() => {
    locationRef.current = location.pathname;
    if (location.pathname === "/projects" && projectsData.length <= 0) {
      getProjectData();
    }
  }, [location.pathname]);

  // Filter && search
  useEffect(() => {
    // Kun søk
    if (
      keyword.length > 1 &&
      mainProjFilter.length === 0 &&
      plProjFilter.length === 0
    ) {
      return setProjects(searchProject(projectsData));
    }
    // Kun type
    if (
      keyword.length <= 1 &&
      mainProjFilter.length !== 0 &&
      plProjFilter.length === 0
    ) {
      setProjects(
        projectsData.filter((item) => mainProjFilter.includes(item.name))
      );
    }
    // Kun PL <= Her vil den fjerne alle andre PL, ikke smart
    if (
      keyword.length <= 1 &&
      mainProjFilter.length === 0 &&
      plProjFilter.length !== 0
    ) {
      const filteredProjects = projectsData
        .map((project) => {
          // Sjekk på prosjektets 'pl' mot plProjFilter
          const projectMatch = project?.pl && plProjFilter.includes(project.pl);

          // Filtrering av subData
          const filteredSubData = project.subData
            .map((sub) => {
              const subMatch = sub?.pl && plProjFilter.includes(sub.pl);

              // Filtrering av subData.subData
              const filteredSubDataInSub = sub.subData.filter((subDataItem) => {
                return subDataItem?.pl && plProjFilter.includes(subDataItem.pl);
              });

              // Hvis det er en match i sub eller i subData.subData, returner sub med filtrerte subData
              if (subMatch || filteredSubDataInSub.length > 0) {
                return {
                  ...sub,
                  subData: filteredSubDataInSub, // Inkluder filtrerte subData
                };
              }

              return null; // Hvis ingen match, return null
            })
            .filter((sub) => sub !== null); // Filtrer ut nuller

          // Returner prosjektet hvis det er en match i prosjektet eller i noen av subData
          if (projectMatch || filteredSubData.length > 0) {
            return {
              ...project,
              subData: filteredSubData, // Inkluder filtrerte subData
              parent: project.parent,
            };
          }

          return null; // Hvis ingen match, return null
        })
        .filter((project) => project !== null); // Filtrer ut nuller

      // Legg til activeSub
      const newActiveSub = filteredProjects.map((mainData) => mainData.name);
      const newActiveSubSub = filteredProjects.flatMap(
        (mainData) => mainData.subData?.map((subData) => subData.pnr) || []
      );

      // Oppdaterer state én gang for hver
      setActiveSub(newActiveSub);
      setActiveSubSub(newActiveSubSub);
      setProjects(filteredProjects);
    }

    // Søk og Type
    if (
      keyword.length > 1 &&
      mainProjFilter.length !== 0 &&
      plProjFilter.length === 0
    ) {
      return setProjects(
        searchProject(
          projectsData.filter((item) => mainProjFilter.includes(item.name))
        )
      );
    }

    // Søk og PL
    if (
      keyword.length > 1 &&
      mainProjFilter.length === 0 &&
      plProjFilter.length !== 0
    ) {
      const filteredProjects = projectsData
        .map((project) => {
          // Sjekk på prosjektets 'pl' mot plProjFilter
          const projectMatch = project?.pl && plProjFilter.includes(project.pl);

          // Filtrering av subData
          const filteredSubData = project.subData
            .map((sub) => {
              const subMatch = sub?.pl && plProjFilter.includes(sub.pl);

              // Filtrering av subData.subData
              const filteredSubDataInSub = sub.subData.filter((subDataItem) => {
                return subDataItem?.pl && plProjFilter.includes(subDataItem.pl);
              });

              // Hvis det er en match i sub eller i subData.subData, returner sub med filtrerte subData
              if (subMatch || filteredSubDataInSub.length > 0) {
                return {
                  ...sub,
                  subData: filteredSubDataInSub, // Inkluder filtrerte subData
                };
              }

              return null; // Hvis ingen match, return null
            })
            .filter((sub) => sub !== null); // Filtrer ut nuller

          // Returner prosjektet hvis det er en match i prosjektet eller i noen av subData
          if (projectMatch || filteredSubData.length > 0) {
            return {
              ...project,
              subData: filteredSubData, // Inkluder filtrerte subData
              parent: project.parent,
            };
          }

          return null; // Hvis ingen match, return null
        })
        .filter((project) => project !== null); // Filtrer ut nuller

      // Legg til activeSub
      const newActiveSub = filteredProjects.map((mainData) => mainData.name);
      const newActiveSubSub = filteredProjects.flatMap(
        (mainData) => mainData.subData?.map((subData) => subData.pnr) || []
      );

      // Oppdaterer state én gang for hver
      setActiveSub(newActiveSub);
      setActiveSubSub(newActiveSubSub);
      setProjects(searchProject(filteredProjects));
    }

    // Type og PL
    if (
      keyword.length <= 1 &&
      mainProjFilter.length !== 0 &&
      plProjFilter.length !== 0
    ) {
      const typeData = projectsData.filter((item) =>
        mainProjFilter.includes(item.name)
      );

      const filteredProjects = typeData
        .map((project) => {
          // Sjekk på prosjektets 'pl' mot plProjFilter
          const projectMatch = project?.pl && plProjFilter.includes(project.pl);

          // Filtrering av subData
          const filteredSubData = project.subData
            .map((sub) => {
              const subMatch = sub?.pl && plProjFilter.includes(sub.pl);

              // Filtrering av subData.subData
              const filteredSubDataInSub = sub.subData.filter((subDataItem) => {
                return subDataItem?.pl && plProjFilter.includes(subDataItem.pl);
              });

              // Hvis det er en match i sub eller i subData.subData, returner sub med filtrerte subData
              if (subMatch || filteredSubDataInSub.length > 0) {
                return {
                  ...sub,
                  subData: filteredSubDataInSub, // Inkluder filtrerte subData
                };
              }

              return null; // Hvis ingen match, return null
            })
            .filter((sub) => sub !== null); // Filtrer ut nuller

          // Returner prosjektet hvis det er en match i prosjektet eller i noen av subData
          if (projectMatch || filteredSubData.length > 0) {
            return {
              ...project,
              subData: filteredSubData, // Inkluder filtrerte subData
              parent: project.parent,
            };
          }

          return null; // Hvis ingen match, return null
        })
        .filter((project) => project !== null); // Filtrer ut nuller

      // Legg til activeSub
      const newActiveSub = filteredProjects.map((mainData) => mainData.name);
      const newActiveSubSub = filteredProjects.flatMap(
        (mainData) => mainData.subData?.map((subData) => subData.pnr) || []
      );

      // Oppdaterer state én gang for hver
      setActiveSub(newActiveSub);
      setActiveSubSub(newActiveSubSub);
      setProjects(filteredProjects);
    }

    if (
      keyword.length <= 1 &&
      mainProjFilter.length === 0 &&
      plProjFilter.length === 0
    ) {
      setProjects(projectsData);
      setActiveSub(oldActiveSub);
      setActiveSubSub(oldActiveSubSub);
    }
  }, [projectsData, keyword, mainProjFilter, plProjFilter]);

  // Update Data mechanism
  useEffect(() => {
    const updateProject = setInterval(() => {
      if (locationRef.current !== "/projects") {
        getProjectData();
      }
    }, 60 * 5 * 1000);

    return () => {
      clearInterval(updateProject);
    };
  }, []); // Empty dependency array, so this effect only runs once

  // Old Sub Mechanism
  useEffect(() => {
    if (
      keyword.length === 0 &&
      mainProjFilter.length === 0 &&
      plProjFilter.length === 0
    ) {
      setOldActiveSub(activeSub);
      setOldActiveSubSub(activeSubSub);
    }
  }, [activeSub, activeSubSub]);

  const clearFilters = useCallback(() => {
    setPlProjFilter([]);
    setMainProjFilter([]);
  }, []);

  const toggleTableMode = useCallback(() => {
    setIsBrutto((prev) => {
      getProjectData(!prev);
      return !prev;
    });
  }, []);

  // Memoize forskjellige verdier eller grupper av verdier
  const projectsMemo = useMemo(
    () => ({
      projects,
      projectsDate,
      getProjectData,
      isBrutto,
      toggleTableMode,
    }),
    [projects, projectsDate]
  );
  const activeSubMemo = useMemo(
    () => ({ activeSub, setActiveSub, activeSubSub, setActiveSubSub }),
    [activeSub, activeSubSub]
  );
  const scrollMemo = useMemo(
    () => ({ projectsTableScrollTop, setProjectsTableScrollTop }),
    [projectsTableScrollTop]
  );
  const filterMemo = useMemo(
    () => ({
      mainProjFilter,
      plProjFilter,
      setMainProjFilter,
      setPlProjFilter,
      mainProjects,
      PLinProjects,
      clearFilters,
    }),
    [mainProjFilter, plProjFilter, mainProjects, PLinProjects]
  );

  const pinMemo = useMemo(() => ({ pinList, setPinList }), [pinList]);
  const errorMemo = useMemo(() => ({ loaded, error }), [loaded, error]);

  const value = {
    ...projectsMemo,
    ...activeSubMemo,
    ...scrollMemo,
    ...pinMemo,
    ...errorMemo,
    ...filterMemo,
    keyword,
    setKeyword,
  };

  /*
  const value = useMemo(
    () => ({
      loaded,
      error,
      projects,
      projectsDate,
      getProjectData,
      activeSub,
      setActiveSub,
      activeSubSub,
      setActiveSubSub,
      projectsTableScrollTop,
      setProjectsTableScrollTop,
      pinList,
      setPinList,
    }),
    [projects, activeSub, activeSubSub, projectsTableScrollTop, pinList]
  );
  */

  return (
    <UserContentContext.Provider value={value}>
      {children}
    </UserContentContext.Provider>
  );
};

export const useContent = () => {
  return useContext(UserContentContext);
};

export const ContentWrapper = ({ children }) => {
  return <ContentProvider>{children}</ContentProvider>;
};
