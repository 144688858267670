import React, { useEffect, useState } from "react";

import { Breadcrumb, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const genRandomId = () => {
  return Math.random(0, 99999) * 10;
};

export default function Types() {
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/types.php?typeData=type",
        {
          credentials: "include",
        }
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      return;
    }
  };

  const updateData = async () => {
    setEditMode(false);

    const postData = {
      data: data,
    };
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/types.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        alert("Endringer er lagret");
        getData();
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="../admin/project/types">Typer</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col col-md-12 col-lg-10 col-sm">
          <div className="position-relative">
            <h4>Typer</h4>
            <span
              style={{
                position: "absolute",
                right: 15,
                top: 10,
                cursor: "pointer",
              }}
              onClick={() =>
                setEditMode((prev) => {
                  if (prev) {
                    updateData();
                  }
                  return !prev;
                })
              }
            >
              {editMode ? "Lagre" : "Endre"}
            </span>
          </div>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th>Navn</th>
                  <th>PL</th>
                  <th>BildeURL</th>
                  <th>Prosjekter</th>
                  <th>Versjon</th>
                  <th>Årstall</th>
                  <th>Faserapport</th>
                  <th>Sperret</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d, dIndex) => {
                  return editMode ? (
                    <tr key={dIndex}>
                      <td>
                        <input
                          type="text"
                          defaultValue={d.name}
                          onChange={(e) => {
                            setData((prev) => {
                              const pos = data.map((e) => e.id).indexOf(d.id);
                              let updValue = {
                                ...prev[pos],
                                name: e.target.value,
                              };
                              let oldPrev = prev;
                              oldPrev[pos] = updValue;
                              return oldPrev;
                            });
                          }}
                          style={{ width: "100%" }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          defaultValue={d.pl}
                          onChange={(e) => {
                            setData((prev) => {
                              const pos = data.map((e) => e.id).indexOf(d.id);
                              let updValue = {
                                ...prev[pos],
                                pl: e.target.value,
                              };
                              let oldPrev = prev;
                              oldPrev[pos] = updValue;
                              return oldPrev;
                            });
                          }}
                          style={{ width: "100%" }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          defaultValue={d.img}
                          onChange={(e) => {
                            setData((prev) => {
                              const pos = data.map((e) => e.id).indexOf(d.id);
                              let updValue = {
                                ...prev[pos],
                                img: e.target.value,
                              };
                              let oldPrev = prev;
                              oldPrev[pos] = updValue;
                              return oldPrev;
                            });
                          }}
                          style={{ width: "100%" }}
                        />
                      </td>
                      <td>{d.numProjects}</td>
                      <td>
                        <input
                          type="text"
                          defaultValue={d.version}
                          onChange={(e) => {
                            setData((prev) => {
                              const pos = data.map((e) => e.id).indexOf(d.id);
                              let updValue = {
                                ...prev[pos],
                                version: e.target.value,
                              };
                              let oldPrev = prev;
                              oldPrev[pos] = updValue;
                              return oldPrev;
                            });
                          }}
                          style={{ width: "100%" }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          defaultValue={d.year}
                          onChange={(e) => {
                            setData((prev) => {
                              const pos = data.map((e) => e.id).indexOf(d.id);
                              let updValue = {
                                ...prev[pos],
                                year: e.target.value,
                              };
                              let oldPrev = prev;
                              oldPrev[pos] = updValue;
                              return oldPrev;
                            });
                          }}
                          style={{ width: "100%" }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          defaultValue={d.phaseReport}
                          onChange={(e) => {
                            setData((prev) => {
                              const pos = data.map((e) => e.id).indexOf(d.id);
                              let updValue = {
                                ...prev[pos],
                                phaseReport: e.target.value,
                              };
                              let oldPrev = prev;
                              oldPrev[pos] = updValue;
                              return oldPrev;
                            });
                          }}
                          style={{ width: "100%" }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          defaultValue={d.disabled}
                          onChange={(e) => {
                            setData((prev) => {
                              const pos = data.map((e) => e.id).indexOf(d.id);
                              let updValue = {
                                ...prev[pos],
                                disabled: e.target.value,
                              };
                              let oldPrev = prev;
                              oldPrev[pos] = updValue;
                              return oldPrev;
                            });
                          }}
                          style={{ width: "100%" }}
                        />
                      </td>
                    </tr>
                  ) : (
                    <tr key={dIndex}>
                      <td>{d.name}</td>
                      <td>{d.pl}</td>
                      <td>{d.img}</td>
                      <td>{d.numProjects}</td>
                      <td>{d.version}</td>
                      <td>{d.year}</td>
                      <td>{d.phaseReport}</td>
                      <td>{d.disabled}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {editMode && (
            <Button
              size="sm"
              variant="info"
              onClick={() =>
                setData((prev) => {
                  let lastLine = prev[prev.length - 1];

                  let emptyLine = {};
                  for (let key in lastLine) {
                    if (key === "id") {
                      emptyLine[key] = genRandomId();
                    } else {
                      emptyLine[key] = "";
                    }
                  }
                  let newPrev = [...prev, emptyLine];

                  console.log(newPrev);

                  return newPrev;
                  /*
                  return {
                    ...prev,
                    economy: updatedEconomy,
                  };
                  */
                })
              }
            >
              Legg til ny type
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
