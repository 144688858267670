import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //const [loggingIn, setLoggingIn] = useState(false);
  const { loggingIn, login } = useAuth();

  /*
  const login = async () => {
    setLoggingIn(true);

    const postData = {
      action: "login",
      email: email,
      password: password,
    };

    try {
      const res = await fetch("https://prosjekt.tkva.no/api/auth.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });

      if (res.ok) {
        const result = await res.json();
        if (result.auth) {
          navigate("../");
          setLoggingIn(false);
        } else {
          alert("Feil brukernavn eller passord");
          setLoggingIn(false);
        }
      }
    } catch (err) {
      alert("Noe gikk galt\n" + err.message);
      setLoggingIn(false);
    }

    return () => {
      setLoggingIn(false);
    };
  };
  */

  return (
    <section className="vh-100 gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div
            className="col-12 col-md-8 col-lg-6 col-xl-5"
            style={{ borderRadius: "1rem" }}
          >
            <div
              className="card bg-dark text-white"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5 text-center bg-dark">
                <div className="mb-md-5 mt-md-4 pb-5">
                  <h2 className="fw-bold mb-2 text-uppercase m2 bg-dark">
                    Logg inn
                  </h2>
                  <div
                    data-mdb-input-init=""
                    className="form-outline form-white mb-4"
                  >
                    <input
                      type="email"
                      id="typeEmailX"
                      placeholder="E-post"
                      disabled={loggingIn && true}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ color: "black", backgroundColor: "grey" }}
                      className="form-control form-control-lg"
                    />
                  </div>
                  <div
                    data-mdb-input-init=""
                    className="form-outline form-white mb-4"
                  >
                    <input
                      type="password"
                      id="typePasswordX"
                      disabled={loggingIn && true}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ color: "black", backgroundColor: "grey" }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          login(email, password);
                        }
                      }}
                      placeholder="Passsord"
                      className="form-control form-control-lg"
                    />
                  </div>
                  {loggingIn ? (
                    <button
                      data-mdb-button-init=""
                      disabled
                      data-mdb-ripple-init=""
                      className="btn btn-outline-light btn-lg px-5 btn-success"
                      type="submit"
                    >
                      Loggger inn...
                    </button>
                  ) : (
                    <button
                      data-mdb-button-init=""
                      data-mdb-ripple-init=""
                      className="btn btn-outline-light btn-lg px-5"
                      type="submit"
                      onClick={() => login(email, password)}
                    >
                      Logg inn
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
