import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { Spinner, Breadcrumb, Table, Button, Alert } from "react-bootstrap";

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString();
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export default function EditProject() {
  const params = useParams();

  // Error handler
  const [loaded, setLoaded] = useState(false);
  const [err, setErr] = useState(false);

  // Projects
  const [projects, setProjects] = useState([]);
  const [dummyProjects, setDummyProjects] = useState([]);
  const [projectInfo, setProjectInfo] = useState([]);

  // Search
  const [keyword, setKeyword] = useState("");

  // Edit Mode
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (params.pnr) {
      getProjectInfo();
    }

    getData();

    return () => {
      setLoaded(false);
      setErr(false);
      setEditMode(false);
      setProjectInfo([]);
      setDummyProjects([]);
    };
  }, [params.pnr]);

  // Søk
  useEffect(() => {
    if (keyword.length > 2) {
      const lowercasedKeyword = keyword.toLowerCase();

      const filteredProjects = dummyProjects
        .map((project) => {
          const projectMatch =
            project.pnr.includes(keyword) ||
            project.pname.toLowerCase().includes(lowercasedKeyword);

          const filteredSubData = project.subData.filter(
            (sub) =>
              sub.pnr.includes(keyword) ||
              sub.pname.toLowerCase().includes(lowercasedKeyword)
          );

          if (projectMatch || filteredSubData.length > 0) {
            return {
              ...project,
              subData: filteredSubData,
            };
          }

          return null;
        })
        .filter((project) => project !== null);

      setProjects(filteredProjects);
    } else {
      setProjects(dummyProjects);
    }
  }, [keyword]);

  const getData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/project.php?edit",
        { credentials: "include" }
      );
      const data = await res.json();

      setProjects(data);
      setDummyProjects(data);
      if (!params.pnr) {
        setLoaded(true);
      }
    } catch (err) {
      setErr(err.message);
      setLoaded(true);
    }
  };

  const getProjectInfo = async () => {
    try {
      const res = await fetch(
        `https://prosjekt.tkva.no/api/admin/project.php?edit=${params.pnr}`,
        { credentials: "include" }
      );
      const data = await res.json();
      if (data.pInfo.length > 0) {
        setProjectInfo(data);
      } else {
        setErr("Finner ikke prosjektet");
      }
      setLoaded(true);
    } catch (err) {
      setErr(err.message);
      setLoaded(true);
    }
  };

  const updateData = async (updData) => {
    const pnr = projectInfo.pInfo[0].pnr;
    const pname = projectInfo.pInfo[0].pname;
    const parent = projectInfo.pInfo[0].parent;
    const enhet = projectInfo.economy[0].enhet;

    const postData = {
      action: "updateData",
      pnr: pnr,
      pname: pname,
      parent: parent,
      economy: updData.details,
      enhet: enhet,
    };
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/project.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        alert("Endringer er lagret");
        getProjectInfo();
      }
    } catch (err) {
      alert("Noe gikk galt.. dessverre");
    }
  };

  const saveChanges = async () => {
    const pnr = projectInfo.pInfo[0].pnr;
    const pname = projectInfo.pInfo[0].pname;
    const economy = projectInfo.economy;
    const parent = projectInfo.pInfo[0].parent;
    const comment = projectInfo.economy[0].comment;
    const enhet = projectInfo.economy[0].enhet;

    const postData = {
      action: "update",
      pnr: pnr,
      pname: pname,
      parent: parent,
      economy: economy,
      comment: comment,
      enhet: enhet,
    };

    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/project.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        alert("Endringer er lagret");
        getProjectInfo();
      }
    } catch (err) {
      alert("Noe gikk galt.. dessverre");
    }
  };

  const deleteProj = async () => {
    const pnr = projectInfo.pInfo[0].pnr;

    const postData = {
      action: "delete",
      pnr: pnr,
    };

    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/project.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        alert("Prosjektet er slettet");
        getProjectInfo();
      }
    } catch (err) {
      alert("Noe gikk galt.. dessverre");
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="../admin/project/edit">Endre prosjekt</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {loaded ? (
        err ? (
          <span className="danger">Noe gikk galt: {err} </span>
        ) : (
          <>
            {Object.keys(projectInfo).length > 0 ? (
              <>
                <div className="row">
                  <div className="col d-flex flex-column">
                    <div className="col">
                      <div className="d-flex position-relative">
                        <h3>Prosjektadministrator</h3>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => deleteProj(projectInfo.pInfo[0].pnr)}
                          style={{
                            position: "absolute",
                            right: 125,
                            top: 10,
                            cursor: "pointer",
                          }}
                        >
                          Slett prosjekt
                        </Button>
                        <span
                          className={
                            editMode
                              ? "btn btn-sm btn-success"
                              : "btn btn-sm btn-primary"
                          }
                          style={{
                            position: "absolute",
                            right: 15,
                            top: 10,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setEditMode((prev) => {
                              if (prev) {
                                saveChanges();
                              }
                              return !prev;
                            })
                          }
                        >
                          {editMode ? "Lagre data" : "Endre data"}
                        </span>
                      </div>
                      <div className="content">
                        <div className="row d-flex flex-row flex-grow-1">
                          <div className="col bg-1 align-items-center m-4 rounded justify-content-center d-flex flex-column p-4">
                            <h5>Prosjektnummer</h5>
                            <span>{projectInfo.pInfo[0].pnr}</span>
                          </div>
                          <div className="col bg-1 align-items-center m-4 rounded justify-content-center d-flex flex-column p-4">
                            <h5>Prosjektnavn</h5>
                            {editMode ? (
                              <input
                                autoFocus
                                type="text"
                                defaultValue={projectInfo.pInfo[0].pname}
                                style={{
                                  border: 0,
                                  backgroundColor: "transparent",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  setProjectInfo((prev) => {
                                    let updInfo = {
                                      ...prev.pInfo[0],
                                      pname: e.target.value, // Sett den nye verdien for pnr her
                                    };
                                    // Lag en kopi av hele pInfo arrayen, og oppdater det første elementet
                                    let updatedPInfo = [
                                      updInfo,
                                      ...prev.pInfo.slice(1),
                                    ];
                                    // Returner den oppdaterte state
                                    return {
                                      ...prev,
                                      pInfo: updatedPInfo,
                                    };
                                  });
                                }}
                              />
                            ) : (
                              <span>{projectInfo.pInfo[0].pname}</span>
                            )}
                          </div>
                          <div className="col bg-1 align-items-center m-4 rounded justify-content-center d-flex flex-column p-4">
                            <h5>Hovedprosjekt</h5>
                            {editMode ? (
                              <input
                                autoFocus
                                type="text"
                                defaultValue={projectInfo.pInfo[0].parent}
                                style={{
                                  border: 0,
                                  backgroundColor: "transparent",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  setProjectInfo((prev) => {
                                    let updInfo = {
                                      ...prev.pInfo[0],
                                      parent: e.target.value, // Sett den nye verdien for pnr her
                                    };
                                    // Lag en kopi av hele pInfo arrayen, og oppdater det første elementet
                                    let updatedPInfo = [
                                      updInfo,
                                      ...prev.pInfo.slice(1),
                                    ];
                                    // Returner den oppdaterte state
                                    return {
                                      ...prev,
                                      pInfo: updatedPInfo,
                                    };
                                  });
                                }}
                              />
                            ) : (
                              <span>{projectInfo.pInfo[0].parent}</span>
                            )}
                          </div>
                          <div className="col bg-1 align-items-center m-4 rounded justify-content-center d-flex flex-column p-4">
                            <h5>Enhet</h5>

                            {editMode ? (
                              <input
                                type="text"
                                defaultValue={projectInfo?.economy[0]?.enhet}
                                style={{
                                  border: 0,
                                  backgroundColor: "transparent",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  setProjectInfo((prev) => {
                                    let updInfo = {
                                      ...prev.economy[0],
                                      enhet: e.target.value, // Sett den nye verdien for pnr her
                                    };
                                    // Lag en kopi av hele pInfo arrayen, og oppdater det første elementet
                                    let updatedPInfo = [
                                      updInfo,
                                      ...prev.economy.slice(1),
                                    ];
                                    // Returner den oppdaterte state
                                    return {
                                      ...prev,
                                      economy: updatedPInfo,
                                    };
                                  });
                                }}
                              />
                            ) : (
                              <span>{projectInfo?.economy[0]?.enhet}</span>
                            )}
                          </div>
                        </div>
                        <Alert variant="warning">
                          <span className="text-muted">
                            (!) Endring av prosjektnavn, hovedprosjekt og enhet
                            vil gjelde for alle versjoner og år
                          </span>
                        </Alert>
                      </div>
                    </div>

                    <div className="col h-100 my-2">
                      <div className="d-flex position-relative">
                        <h4>Økonomiplan</h4>
                        <span
                          style={{ position: "absolute", right: 15, top: 15 }}
                        >
                          Oppdatert{" "}
                          {formatDate(projectInfo?.economy[0]?.updated)}
                        </span>
                      </div>
                      <div className="content">
                        <Table>
                          <thead>
                            <tr>
                              <th>Konto</th>
                              <th>Tjeneste</th>
                              <th>Tema</th>
                              {Array.from({ length: 10 }, (_, index) => {
                                return (
                                  <th key={index}>
                                    {parseInt(projectInfo?.economy[0]?.year) +
                                      index}
                                  </th>
                                );
                              })}
                              <th>Notat</th>
                            </tr>
                          </thead>
                          <tbody>
                            {projectInfo?.economy?.map((economy, index) => {
                              return (
                                <tr key={index}>
                                  {editMode ? (
                                    <>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        konto: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.konto}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        tjeneste:
                                                          e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.tjeneste}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        tema: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.tema}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e1: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e1}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e2: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e2}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e3: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e3}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e4: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e4}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e5: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e5}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e6: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e6}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e7: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e7}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e8: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e8}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e9: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e9}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        e10: e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.e10}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            setProjectInfo((prev) => {
                                              const updatedEconomy =
                                                prev.economy.map(
                                                  (item, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...item,
                                                        lineComment:
                                                          e.target.value,
                                                      };
                                                    }
                                                    return item;
                                                  }
                                                );
                                              return {
                                                ...prev,
                                                economy: updatedEconomy,
                                              };
                                            });
                                          }}
                                          style={{ border: 0, width: "100%" }}
                                          defaultValue={economy.lineComment}
                                        />
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>{economy.konto}</td>
                                      <td>{economy.tjeneste}</td>
                                      <td>{economy.tema}</td>
                                      <td>{convertNumber(economy.e1)}</td>
                                      <td>{convertNumber(economy.e2)}</td>
                                      <td>{convertNumber(economy.e3)}</td>
                                      <td>{convertNumber(economy.e4)}</td>
                                      <td>{convertNumber(economy.e5)}</td>
                                      <td>{convertNumber(economy.e6)}</td>
                                      <td>{convertNumber(economy.e7)}</td>
                                      <td>{convertNumber(economy.e8)}</td>
                                      <td>{convertNumber(economy.e9)}</td>
                                      <td>{convertNumber(economy.e10)}</td>
                                      <td>{economy.lineComment}</td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        {editMode && (
                          <>
                            <Button
                              size="sm"
                              variant="info"
                              onClick={() =>
                                setProjectInfo((prev) => {
                                  // Hent den siste linjen i projectInfo.economy
                                  let lastLine =
                                    prev.economy[prev.economy.length - 1];

                                  // Opprett en ny linje med de samme nøklene, men med tomme verdier eller spesifikke verdier
                                  let emptyLine = {};
                                  for (let key in lastLine) {
                                    if (key === "tjeneste") {
                                      emptyLine[key] = "1900";
                                    } else if (
                                      [
                                        "parent",
                                        "type_id",
                                        "version",
                                        "year",
                                        "pnr",
                                        "pname",
                                      ].includes(key)
                                    ) {
                                      emptyLine[key] = lastLine[key];
                                    } else {
                                      emptyLine[key] = "";
                                    }
                                  }

                                  // Legg til den nye linjen på slutten av arrayen
                                  let updatedEconomy = [
                                    ...prev.economy,
                                    emptyLine,
                                  ];

                                  // Returner den oppdaterte state
                                  return {
                                    ...prev,
                                    economy: updatedEconomy,
                                  };
                                })
                              }
                            >
                              Legg til linje
                            </Button>
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={() => {
                                setProjectInfo((prev) => {
                                  // Sjekk om economy-arrayet eksisterer og har minst ett element
                                  if (
                                    !prev.economy ||
                                    prev.economy.length <= 1
                                  ) {
                                    alert("Kan ikke slette siste linje");
                                    return prev;
                                  }

                                  // Lag en ny array uten det siste elementet
                                  const updatedEconomy = prev.economy.slice(
                                    0,
                                    -1
                                  );

                                  // Returner den nye tilstanden med oppdatert economy-array
                                  return {
                                    ...prev,
                                    economy: updatedEconomy,
                                  };
                                });
                              }}
                            >
                              Slett siste linje
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col h-100 my-2">
                      <h4>Kommentar</h4>
                      <div className="content">
                        {editMode ? (
                          <textarea
                            defaultValue={projectInfo?.economy[0]?.comment}
                            style={{ width: "100%", height: 120, border: 0 }}
                            onChange={(e) =>
                              setProjectInfo((prev) => {
                                let updInfo = {
                                  ...prev.economy[0],
                                  comment: e.target.value, // Sett den nye verdien for pnr her
                                };
                                // Lag en kopi av hele pInfo arrayen, og oppdater det første elementet
                                let updatedPInfo = [
                                  updInfo,
                                  ...prev.economy.slice(1),
                                ];
                                // Returner den oppdaterte state
                                return {
                                  ...prev,
                                  economy: updatedPInfo,
                                };
                              })
                            }
                          />
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: projectInfo.economy[0]?.comment
                                ? projectInfo.economy[0].comment.replaceAll(
                                    "\n",
                                    "<br>"
                                  )
                                : "",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-sm d-xxl-block d-xl-none">
                    <h3>Liste over prosjekter</h3>
                    <div
                      className="bg-2 h-100"
                      style={{ maxHeight: "440px", overflowY: "auto" }}
                    >
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">
                            <i className="bi bi-search" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Søk etter prosjekt"
                          onChange={(e) => setKeyword(e.target.value)}
                        />
                      </div>
                      <ul style={{ listStyle: "none" }}>
                        {projects.map((proj, index) => {
                          return (
                            <li key={index} style={{ padding: 5 }}>
                              <Link
                                to={"../admin/project/edit/" + proj.pnr}
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 18,
                                  color: "#5e6e82",
                                }}
                              >
                                {proj.pnr} - {proj.pname}
                              </Link>
                              <ul>
                                {proj.subData.map((subProj, subIndex) => {
                                  return (
                                    <li key={subIndex} style={{ padding: 5 }}>
                                      <Link
                                        to={
                                          "../admin/project/edit/" + subProj.pnr
                                        }
                                        style={{
                                          fontSize: 16,
                                          color: "#5e6e82",
                                        }}
                                      >
                                        {subProj.pnr} - {subProj.pname}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row my-4">
                  <h4>Grunnlagsdata</h4>

                  {projectInfo.details.map((pInfo, pIndex) => {
                    return (
                      <div className="col" key={pIndex}>
                        {pInfo.versions.map((pVersion, vIndex) => {
                          return (
                            <React.Fragment key={vIndex}>
                              <div className="bg-striped">
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th>Årstall</th>
                                      <th>Versjon</th>
                                      <th>Konto</th>
                                      <th>Tjeneste</th>
                                      <th>Tema</th>
                                      {Array.from(
                                        { length: 10 },
                                        (_, index) => {
                                          return (
                                            <th key={index}>
                                              {parseInt(pInfo.year) + index}
                                            </th>
                                          );
                                        }
                                      )}
                                      <th>Notat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pVersion.details.map((pDetail, dKey) => {
                                      return (
                                        <tr key={dKey}>
                                          <td>{pInfo.year}</td>
                                          <td>{pDetail.version}</td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.konto}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].konto =
                                                    newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.tjeneste}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].tjeneste =
                                                    newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.tema}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].tema =
                                                    newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e1}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e1 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>

                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e2}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e2 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e3}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e3 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e4}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e4 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e5}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e5 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e6}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e6 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e7}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e7 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e8}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e8 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e9}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e9 = newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.e10}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].e10 =
                                                    newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="text"
                                              style={{
                                                border: 0,
                                                width: "100%",
                                              }}
                                              defaultValue={pDetail.lineComment}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                setProjectInfo((prev) => {
                                                  const newDetails = [
                                                    ...prev.details,
                                                  ];
                                                  newDetails[pIndex].versions[
                                                    vIndex
                                                  ].details[dKey].lineComment =
                                                    newValue;
                                                  return {
                                                    ...prev,
                                                    details: newDetails,
                                                  };
                                                });
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                                <textarea
                                  style={{
                                    border: 0,
                                    width: "100%",
                                    height: "auto",
                                  }}
                                  defaultValue={
                                    projectInfo.details[pIndex].versions[vIndex]
                                      .details[0].comment
                                  }
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setProjectInfo((prev) => {
                                      const newDetails = [...prev.details];
                                      newDetails[pIndex].versions[
                                        vIndex
                                      ].details[0].comment = newValue;

                                      return {
                                        ...prev,
                                        details: newDetails,
                                      };
                                    });
                                  }}
                                />
                                <div className="d-flex">
                                  <Button
                                    className="m-2"
                                    variant="warning"
                                    size="sm"
                                    onClick={() => {
                                      const versionData =
                                        projectInfo.details[pIndex].versions[
                                          vIndex
                                        ];
                                      updateData(versionData);
                                    }}
                                  >
                                    Oppdater data
                                  </Button>
                                  <Button
                                    className="m-2"
                                    variant="success"
                                    size="sm"
                                    onClick={() => {
                                      setProjectInfo((prev) => {
                                        const newDetails = [...prev.details];
                                        const thisYear = newDetails[pIndex];

                                        // Legg til en ny linje med eksempeldata
                                        thisYear.versions[vIndex].details.push({
                                          type_id:
                                            thisYear.versions[vIndex].details[0]
                                              .type_id,
                                          version:
                                            thisYear.versions[vIndex].version,
                                          year: pInfo.year,
                                          konto: "",
                                          tjeneste: "",
                                          tema: "",
                                          e1: "",
                                          e2: "",
                                          e3: "",
                                          e4: "",
                                          e5: "",
                                          e6: "",
                                          e7: "",
                                          e8: "",
                                          e9: "",
                                          e10: "",
                                          lineComment: "",
                                        });

                                        return { ...prev, details: newDetails };
                                      });
                                    }}
                                  >
                                    Legg til linje
                                  </Button>
                                  <Button
                                    className="m-2"
                                    variant="danger"
                                    size="sm"
                                    onClick={() => {
                                      setProjectInfo((prev) => {
                                        const newDetails = [...prev.details];
                                        const thisYear = newDetails[pIndex];

                                        // Sjekk om det er mer enn én linje før sletting
                                        if (
                                          thisYear.versions[vIndex].details
                                            .length > 1
                                        ) {
                                          // Slett siste linje fra versjonsdetaljene
                                          thisYear.versions[vIndex].details =
                                            thisYear.versions[
                                              vIndex
                                            ].details.slice(0, -1);
                                        } else {
                                          alert("Kan ikke slette siste linje.");
                                        }

                                        return { ...prev, details: newDetails };
                                      });
                                    }}
                                  >
                                    Slett siste linje
                                  </Button>
                                </div>
                                <hr />
                              </div>
                              <div className="mb-4" />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>

                <div className="row my-4 d-none">
                  <h4>Prosjektlogg</h4>
                  <div className="content">
                    {projectInfo.details.map((pInfo, pIndex) => {
                      return (
                        <div className="col" key={pIndex}>
                          <h5 style={{ marginLeft: 25 }}>
                            Årstall: {pInfo.year}
                          </h5>
                          <div className="content">
                            {pInfo.versions.map((pVersion, vIndex) => {
                              return (
                                <div
                                  className="col bg-1"
                                  style={{
                                    minHeight: 100,
                                    padding: 10,
                                    marginBottom: 15,
                                  }}
                                  key={vIndex}
                                >
                                  <b>
                                    Versjon {pVersion.version} - Sist oppdatert:{" "}
                                    {formatDate(pVersion.details[0].updated)}
                                  </b>
                                  {pVersion.details[0].comment && (
                                    <>
                                      <hr />
                                      <div className="d-flex">
                                        <div>{pVersion.details[0].comment}</div>
                                      </div>
                                    </>
                                  )}
                                  <hr />
                                  <div className="col">
                                    <Table>
                                      <thead>
                                        <tr>
                                          <th>Konto</th>
                                          <th>Tjeneste</th>
                                          {Array.from(
                                            { length: 10 },
                                            (_, index) => {
                                              return (
                                                <th key={index}>
                                                  {parseInt(pInfo.year) + index}
                                                </th>
                                              );
                                            }
                                          )}
                                          <th>Notat</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {pVersion.details.map(
                                          (pDetail, dKey) => {
                                            return (
                                              <tr key={dKey}>
                                                <td>{pDetail.konto}</td>
                                                <td>{pDetail.tjeneste}</td>
                                                <td>
                                                  {convertNumber(pDetail.e1)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e2)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e3)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e4)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e5)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e6)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e7)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e8)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e9)}
                                                </td>
                                                <td>
                                                  {convertNumber(pDetail.e10)}
                                                </td>
                                                <td>{pDetail.lineComment}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Endre prosjekt Startside */}
                <div className="col-8">
                  <h3>Liste over prosjekter</h3>
                  <div className="content">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="bi bi-search" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Søk etter prosjekt"
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                    </div>

                    <ul style={{ listStyle: "none" }}>
                      {projects.map((proj, index) => {
                        return (
                          <li key={index} style={{ padding: 5 }}>
                            <Link
                              to={"../admin/project/edit/" + proj.pnr}
                              style={{
                                textDecoration: "underline",
                                fontSize: 18,
                                color: "#5e6e82",
                              }}
                            >
                              {proj.pnr} - {proj.pname}
                            </Link>
                            <ul>
                              {proj.subData.map((subProj, subIndex) => {
                                return (
                                  <li key={subIndex} style={{ padding: 5 }}>
                                    <Link
                                      to={
                                        "../admin/project/edit/" + subProj.pnr
                                      }
                                      style={{
                                        fontSize: 16,
                                        color: "#5e6e82",
                                      }}
                                    >
                                      {subProj.pnr} - {subProj.pname}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* Endre prosjekt Startside Slutt */}
              </>
            )}
          </>
        )
      ) : (
        <Spinner />
      )}{" "}
    </>
  );
}
