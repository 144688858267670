import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import Template from "./pages/index/template";

import Overview from "./pages/overview";
import Projects from "./pages/projects";
import Admin from "./pages/admin";
import NewProject from "./pages/admin/NewProject";
import EditProject from "./pages/admin/EditProject";
import Version from "./pages/admin/Project/Version";
import Types from "./pages/admin/Types";
import PL from "./pages/admin/Project/Pl";
import Reports from "./pages/reports";
import ReportAdmin from "./pages/admin/Project/Reports";
import Users from "./pages/admin/Users";
import { History } from "./pages/history";
import Document from "./pages/document";
import PowerBI from "./pages/powerbi";
import NotReported from "./pages/admin/Project/Reports_NotSent";
import AcceptedReports from "./pages/admin/Project/Reports_Accepted";

import UserAccess from "./pages/admin/Access";
import AdminLogs from "./pages/admin/Logs";
import NoFoundPage from "./pages/index/error";
import Profile from "./pages/profile";
import { ImportForbruk } from "./pages/admin/Import";
import { ContentWrapper } from "./hooks/projects";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ContentWrapper>
            <Template page={"overview"} headline={"Oversikt"}>
              <Overview />
            </Template>
          </ContentWrapper>
        }
      />
      <Route path="/login" element={<Login />} />

      <Route
        path="*"
        element={
          <ContentWrapper>
            <Template page="error" headline={"Error"}>
              <NoFoundPage />
            </Template>
          </ContentWrapper>
        }
      />

      <Route
        path="/profile"
        element={
          <ContentWrapper>
            <Template page="profile" headline={"Profil"}>
              <Profile />
            </Template>
          </ContentWrapper>
        }
      />

      <Route
        path="/projects"
        element={
          <ContentWrapper>
            <Template page={"projects"} headline={"Prosjekter"}>
              <Projects />
            </Template>
          </ContentWrapper>
        }
      />

      <Route
        path="/history"
        element={
          <ContentWrapper>
            <Template page="history" headline={"Historikk"}>
              <History />
            </Template>
          </ContentWrapper>
        }
      />

      <Route
        path="/document"
        element={
          <ContentWrapper>
            <Template page="document" headline={"Dokumenteksport (beta)"}>
              <Document />
            </Template>
          </ContentWrapper>
        }
      />

      <Route
        path="/reports/:pnr?"
        element={
          <ContentWrapper>
            <Template page={"reports"} headline={"Rapportering"}>
              <Reports />
            </Template>
          </ContentWrapper>
        }
      />

      <Route
        path="/powerbi/:type?"
        element={
          <ContentWrapper>
            <Template page="powerbi" headline={"Power BI"}>
              <PowerBI />
            </Template>
          </ContentWrapper>
        }
      />

      <Route
        path="/admin"
        element={
          <Template page={"admin"} headline={"Administrator"}>
            <Admin />
          </Template>
        }
      />
      <Route
        path="/admin/project/new"
        element={
          <Template page={"admin"} headline={"Nytt prosjekt"}>
            <NewProject />
          </Template>
        }
      />
      <Route
        path="/admin/project/edit/:pnr?"
        element={
          <Template page={"admin"} headline={"Endre prosjekt"}>
            <EditProject />
          </Template>
        }
      />
      <Route
        path="/admin/project/version/:typeId?"
        element={
          <Template page={"admin"} headline={"Versjoner"}>
            <Version />
          </Template>
        }
      />
      <Route
        path="/admin/project/types"
        element={
          <Template page={"admin"} headline={"Typer"}>
            <Types />
          </Template>
        }
      />
      <Route
        path="/admin/project/reports/:type_id/:pnr?"
        element={
          <Template page={"admin"} headline={"Rapportering"}>
            <ReportAdmin />
          </Template>
        }
      />

      <Route
        path="/admin/project/notreported/:type_id/:name?"
        element={
          <Template page={"admin"} headline={"Rapportering"}>
            <NotReported />
          </Template>
        }
      />

      <Route
        path="/admin/project/okreported/:type_id/:name?"
        element={
          <Template page={"admin"} headline={"Rapportering"}>
            <AcceptedReports />
          </Template>
        }
      />

      <Route
        path="/admin/project/pl"
        element={
          <Template page={"admin"} headline={"Prosjektledere"}>
            <PL />
          </Template>
        }
      />
      <Route
        path="/admin/users"
        element={
          <Template page="admin" headline="Brukere">
            <Users />
          </Template>
        }
      />
      <Route
        path="/admin/users/access/:id"
        element={
          <Template page="admin" headline="Tilganger">
            <UserAccess />
          </Template>
        }
      />
      <Route
        path="/admin/import/forbruk"
        element={
          <Template page="admin" headline={"Importer forbruk"}>
            <ImportForbruk />
          </Template>
        }
      />
      <Route
        path="/admin/logs"
        element={
          <Template page="admin" headline="Loggfil">
            <AdminLogs />
          </Template>
        }
      />
    </Routes>
  );
}

export default App;
