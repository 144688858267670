import React, { useEffect, useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function NotReported() {
  const params = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/reports.php?notReported=" +
          params.type_id,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      return;
    }
  };

  const sendMessage = async (pnr) => {
    const postData = {
      action: "SEND_REMINDER",
      pnr: pnr,
    };
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/reports.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (res.ok) {
        alert("Påminnelse er sendt!");
      } else {
        alert("Feilmelding: Påminnelse ble ikke sendt");
      }
    } catch (err) {
      alert("Feilmelding: Påminnelse ble ikke sendt");
    }
  };

  const forceSend = async (pnr) => {
    const postData = {
      action: "FORCE_SEND",
      pnr: pnr,
    };
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/reports.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (res.ok) {
        getData();
      }
    } catch (err) {
      alert("Feilmelding: Påminnelse ble ikke sendt");
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link
            to={
              "../admin/project/notreported/" +
              params.type_id +
              "/" +
              params.name
            }
          >
            Ikke rapporterte prosjekt
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Alert style={{ maxWidth: "1200px" }}>
        Prosjekter som ikke er rapportert for {params.name}
      </Alert>
      <div
        className="content mb-2 d-flex flex-row-reverse"
        style={{ maxWidth: "1200px" }}
      >
        <Button size="sm" variant="primary" disabled>
          Send påminnelse
        </Button>
      </div>
      <div style={{ maxHeight: "80%", overflowY: "auto", maxWidth: "1200px" }}>
        <ol className="list-group me-2">
          {data?.map((d, k) => {
            return (
              <li key={k} className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>
                    {d.pnr} - {d.pname} <br />
                    {d.pl}
                  </span>
                  <div className="d-flex">
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <span style={{ color: "white" }}>
                            Send prosjektet til godkjenning
                          </span>
                        </Tooltip>
                      }
                    >
                      <Button
                        className="mx-2"
                        size="sm"
                        variant="danger"
                        onClick={() => forceSend(d.pnr)}
                      >
                        Tving gjennom
                      </Button>
                    </OverlayTrigger>
                    <Button
                      size="sm"
                      variant="warning"
                      onClick={() => sendMessage(d.pnr)}
                    >
                      Send påminnelse
                    </Button>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
}
